import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import FiftyFifty from '../components/fiftyfifty'
import BgImage from "../components/bgImage"

const SteelWindows = (props) => {
  return(
    <section>
      <SEO title="Latest news" description='Our products have a very narrow focus and are ideal for historical renovation, high-end residential and boutique commercial buildings' />
      <BgImage 
        image={props.data.mainImage.childImageSharp.fluid}
        text="Our products have a very narrow focus and are ideal for historical renovation, high-end residential and boutique commercial buildings. Windows and doors crafted from our profiles are often the most prominent feature in prestigious estates and custom homes."
        title="Steel Windows"
        subtitle="Minimalistic modern design"
      ></BgImage>
      <FiftyFifty  data={props.data.allProducts} type="products" /> 
    </section>
  )
}
export const query  = graphql`
{
  mainImage: file(relativePath: {regex: "/50slim/"}) {
    childImageSharp {
      fluid(maxWidth: 1980) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  allProducts(sort: {fields: brand___brand, order: DESC}) {
    edges {
      node {
        name
        intro
        localImage {
          childImageSharp {
            fluid(maxWidth: 1980) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        brand {
          brand
        }
        id
      }
    }
  }
}
`

export default SteelWindows